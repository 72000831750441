// Import dependencies
import React from "react";
import { navigate } from "gatsby"

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import HeaderSeparator from "../components/separators/headerSeparator";
import CategoryDrawer from "../components/cateogry-drawer/categoty-drawer";
import SearchBar from "../components/search-bar/searchBar";
import Fuss from "../assets/images/ree_store.png";
import ChallengeIMG from "../assets/images/challenge.png";
import ChallengeBannerIMG from "../assets/images/challenge_banner.png";
import CardsList from "../components/index/cards-list";

// Import from Redux
import {connect} from "react-redux";
import { challengesLoaded } from "../redux/actions/challengesAction"


import {getFirebase} from "../api/firebase";

import "./reward-center.css";


/*
    Confirmation step of the checkout process page
*/
class RewardCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        section: "main",
        tabs: "See all",
        cash: "-"
    }
  }

  isBrowser = () => typeof window !== "undefined";

  componentDidMount() {
    if (!this.props.loggedIn && this.isBrowser()) {
        navigate("/login", {
            state: {
                redirect: "reward-center"
            }
        })
      }  

    const {fetchedCallback = _ => _} = this.props;
    const app = import("firebase/app");
    const firestore = import("firebase/firestore");

    Promise.all([app, firestore])
        .then(([firebase]) =>
            getFirebase(firebase)
                .firestore()
                .collection("users")
                .doc(this.props.userToken.uid)
                .get()
                .then(doc => {
                    if (doc.exists) {
                        this.setState({cash :doc.data().cash});
                    }
                })
        )
        .catch(fetchedCallback);

    Promise.all([app, firestore])
        .then(([firebase]) =>
            getFirebase(firebase)
                .firestore()
                .collection("challenges")
                .get()
                .then(querySnapshot => {
                    if (Array.isArray(querySnapshot) && querySnapshot.length === 0)
                        fetchedCallback();
                    let challenges = []
                    querySnapshot.forEach(doc => {
                        // Get data
                        challenges = [...challenges, doc.data()]
                    });
                    this.props.dispatch(challengesLoaded(challenges));

                })
        )
        .catch(fetchedCallback);

    }

  render() {
    return (
      <Layout noFooter noWhatsapp>
        <SEO title="Reward Center" />
        <CategoryDrawer/>
        <SearchBar/>
        <div className="reward_center_container">
            {
                this.state.section != "main" &&
                    <div className="reward_center_section">

                    <div className="reward_center_section_flex">
                        <svg onClick={() => this.setState({section: "main"})} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 7.0007H3.82998L8.70998 2.1207C9.09998 1.7307 9.09998 1.0907 8.70998 0.700703C8.31998 0.310703 7.68998 0.310703 7.29998 0.700703L0.70998 7.2907C0.31998 7.6807 0.31998 8.3107 0.70998 8.7007L7.29998 15.2907C7.68998 15.6807 8.31998 15.6807 8.70998 15.2907C9.09998 14.9007 9.09998 14.2707 8.70998 13.8807L3.82998 9.0007H15C15.55 9.0007 16 8.5507 16 8.0007C16 7.4507 15.55 7.0007 15 7.0007Z" fill="black"/>
                        </svg>
                        {this.state.section == "my_rewards" && <span>My Rewards</span>}
                        {this.state.section == "challenges" && <span>Challenges</span>}
                    </div>
                </div>
            }
            <div className={this.state.section == "main" ? "reward_center_header reward_center_header_no_border_radius" : "reward_center_header"}  style={{backgroundImage: "url('" + ChallengeBannerIMG + "')"}}>
                <h1>Reward center</h1>
                {
                    this.state.cash != "-" ? <span>You have {this.state.cash} Reinin Coins</span> : <span>Loading...</span>
                }
            </div>
            <div className="reward_center_menu">
                <div className="reward_center_menu_item" onClick={() => this.setState({section: "my_rewards"})}>
                    My rewards
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.956624 0.709778C0.566624 1.09978 0.566624 1.72978 0.956624 2.11978L4.83662 5.99978L0.956624 9.87978C0.566624 10.2698 0.566624 10.8998 0.956624 11.2898C1.34662 11.6798 1.97662 11.6798 2.36662 11.2898L6.95662 6.69978C7.34662 6.30978 7.34662 5.67978 6.95662 5.28978L2.36662 0.699778C1.98662 0.319778 1.34662 0.319778 0.956624 0.709778Z" fill="#697386"/>
                    </svg>
                </div>
                <a href="https://help.reinin.sg/" className="reward_center_menu_item">
                    {this.state.section != "challenges" ? "Rewards FAQs" : "Challenges FAQs" }
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.956624 0.709778C0.566624 1.09978 0.566624 1.72978 0.956624 2.11978L4.83662 5.99978L0.956624 9.87978C0.566624 10.2698 0.566624 10.8998 0.956624 11.2898C1.34662 11.6798 1.97662 11.6798 2.36662 11.2898L6.95662 6.69978C7.34662 6.30978 7.34662 5.67978 6.95662 5.28978L2.36662 0.699778C1.98662 0.319778 1.34662 0.319778 0.956624 0.709778Z" fill="#697386"/>
                    </svg>
                </a>
            </div>

            {this.state.section == "main" && 
                <div className="cards-list-container no-padding cards-list-two-info cards-list-rewards">
                <div className="cards-list">
                    <div className="cards-list-item cards-list-item-large" style={{backgroundImage: "url('" + ChallengeIMG + "')"}}>
                        <div className="card-description">
                            <h2 className="card-title">Challenges</h2>
                            <p>Complete simple recycling challenges in order to win discounts, prizes, cash, and Reinin Coins</p>
                            <span className="recycle-link challenges-link" style={{justifyContent: "flex-start"}}  onClick={() => this.setState({section: "challenges"})}>
                                <span>See challenges</span>
                                <span>See all</span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="cards-list-item cards-list-item-large" style={{backgroundImage: "url('" + Fuss + "')"}}>
                        <div className="card-description">
                            <h2 className="card-title">Ree Store</h2>
                            <p>Use Reinin Coins for discounts on a highly curated range of eco-friendly essentials!</p>
                            <span className="card-comming-soon">COMING SOON</span>
                        </div>
                    </div>
                </div>
                </div>
            }

            {
                this.state.section == "my_rewards" &&
                <div className="rewards_redeemed_container">
                    <h1>Rewards redeemed</h1>
                    <div className="cards-list-container no-padding cards-list-two-info cards-list-rewards">
                        <CardsList
                            noContainer
                            cards={
                                this.props.challenges.challenges
                                .filter(challenge => this.props.userToken ? challenge.users.includes(this.props.userToken.uid) : !challenge)
                                .map(challenge =>
                                    ["challenge_reward", challenge.reward, challenge.title, "/", challenge.brand.replace(/[^\w\s]/gi, '').replace(/\s/g, '').toLowerCase()]    
                                )
                            }
                        />
                    </div>
                </div>
            }

            {
                this.state.section == "challenges" &&
                <div className="rewards_challenges_container">
                    <div className="rewards_challenges_tab_container">
                        <div className={this.state.tabs == "See all" ? "rewards_challenges_tab active" : "rewards_challenges_tab"} onClick={() => this.setState({tabs: "See all"})}>See all</div>
                        <div className={this.state.tabs == "Reinin" ? "rewards_challenges_tab active" : "rewards_challenges_tab"} onClick={() => this.setState({tabs: "Reinin"})}>Reinin</div>
                        <div className={this.state.tabs == "Electronics" ? "rewards_challenges_tab active" : "rewards_challenges_tab"} onClick={() => this.setState({tabs: "Electronics"})}>Electronics</div>
                        <div className={this.state.tabs == "Clothes" ? "rewards_challenges_tab active" : "rewards_challenges_tab"} onClick={() => this.setState({tabs: "Clothes"})}>Clothes</div>
                        <div className={this.state.tabs == "Books" ? "rewards_challenges_tab active" : "rewards_challenges_tab"} onClick={() => this.setState({tabs: "Books"})}>Books</div>
                        <div className={this.state.tabs == "Cookware" ? "rewards_challenges_tab active" : "rewards_challenges_tab"} onClick={() => this.setState({tabs: "Cookware"})}>Cookware</div>
                    </div>

                    {
                        this.props.challenges.challenges
//                        .filter(challenge => this.props.userToken ? !challenge.users.includes(this.props.userToken.uid) : challenge)
                        .filter(challenge => this.state.tabs == "Reinin" ? challenge.brand == "reinin" : challenge)
                        .filter(challenge => this.state.tabs != "Reinin" && this.state.tabs != "See all" ? challenge.category == this.state.tabs : challenge)
                        .map(challenge => challenge.brand[0].toUpperCase())
                        .filter(function(item, pos, self) {
                            return self.indexOf(item) == pos;
                        })
                        .map(challengeLetter => 
                            <>
                                <h1>{challengeLetter}</h1>
                                <CardsList
                                    noContainer
                                    cards={
                                        this.props.challenges.challenges
//                                        .filter(challenge => this.props.userToken ? !challenge.users.includes(this.props.userToken.uid) : challenge)
                                        .filter(challenge => challenge.brand[0].toUpperCase() == challengeLetter)
                                        .filter(challenge => this.state.tabs == "Reinin" ? challenge.brand == "reinin" : challenge)
                                        .filter(challenge => this.state.tabs != "Reinin" && this.state.tabs != "See all" ? challenge.category == this.state.tabs : challenge)
                                        .map(challenge =>
                                            ["challenge", challenge.reward, challenge.title, "/", challenge.brand.replace(/[^\w\s]/gi, '').replace(/\s/g, '').toLowerCase()]    
                                        )
                                    }
                                />
                            </>
                        )
                    }
                </div>
            }

        </div>
    </Layout>
  );
  }
}

// Connect redux to component
const mapStateToProps = state => ({
    loggedIn: state.user.isLoggedIn,
    userToken: state.user.userToken,
    challenges: state.challenges,
})

export default connect(mapStateToProps)(RewardCenter)
